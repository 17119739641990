<template>
  <div id="round-member-report" class="container-fluid">
    <div class="page-header mb-1">
      <strong>ดูของสมาชิก</strong>
    </div>
    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <h5 class="card-title">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>

        <ol class="breadcrumb px-2 py-1 m-0 border-info" :class="[{'mb-2': rates.length > 1}]">
          <li class="breadcrumb-item" v-for="breadcrumb in breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
            <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
            <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId, null, true)">{{ breadcrumb.username }}</a>
          </li>
        </ol>

        <b-nav v-if="rates.length > 1" card-header tabs>
          <b-nav-item @click="toggleRate(null)" :active="rateId===null">ทั้งหมด</b-nav-item>
          <b-nav-item
            v-for="rate in rates"
            @click="toggleRate(rate._id)"
            :active="rateId===rate._id"
            :key="rate._id"
          >{{ rate.rateTitle }}</b-nav-item>
        </b-nav>
      </b-card-header>

      <b-card-body class="p-2">
        <MemberSetsNumbers
          v-if="display==='Member'"
          :accountId="accountId"
          :roundId="roundId"
          :rateId="rateId"
          @breadcrumbs="setBreadcrumbs" />
        <table v-else class="table-sets-report-account table table-bordered table-info mb-0">
          <thead>
            <tr>
              <th rowspan="2">ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th width="12%" rowspan="2">ระดับ</th>
              <th width="10%" rowspan="2" style="border-right: 2px solid #86cfda;">ยอดทั้งหมด</th>
              <th colspan="3" style="border-right: 2px solid #86cfda;">สมาชิก</th>
              <th colspan="3" style="border-right: 2px solid #86cfda;">{{agentName}}</th>
              <th colspan="3">บริษัท</th>
            </tr>
            <tr>
              <th width="6%" class="p-1">ส่งออก</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1" style="border-right: 2px solid #86cfda;">รวม</th>
              <th width="6%" class="p-1">ถือหุ้น</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1" style="border-right: 2px solid #86cfda;">รวม</th>
              <th width="6%" class="p-1">ถือหุ้น</th>
              <th width="6%" class="p-1">คอมฯ</th>
              <th width="6%" class="p-1">รวม</th>
            </tr>
          </thead>
          <tbody v-if="accounts.length && !isLoading">
            <tr v-for="item in accounts" :key="item.agent.childAccountId">
              <td class="p-0 px-1 text-center">
                <a v-if="item.agent.role==='Agent'" href="#" @click="toggleChildAccount(item.agent.childAccountId)">{{ item.agent.username }}</a>
                <a v-else href="#" @click="toggleMemberAccount(item.agent.memberAccountId)">{{ item.agent.username }}</a>
                <small v-if="showName" class="text-secondary ml-1">({{item.agent.firstName}})</small>
              </td>
              <td class="text-center">
                <AccountRole :role="item.agent.role" />
              </td>
              <td class="text-right px-1 text-success" style="border-right: 2px solid #86cfda;">{{ item.summary.amount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-danger">{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-success">{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-danger" style="border-right: 2px solid #86cfda;">{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.agentAmount>0
                }, {
                  'text-danger': item.summary.agentAmount<0
                }]">{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.agentCommission>0
                }, {
                  'text-danger': item.summary.agentCommission<0
                }]">{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1" style="border-right: 2px solid #86cfda;"
                :class="[{
                  'text-success': item.summary.agentTotal>0
                }, {
                  'text-danger': item.summary.agentTotal<0
                }]">{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyAmount>0
                }, {
                  'text-danger': item.summary.companyAmount<0
                }]">{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyCommission>0
                }, {
                  'text-danger': item.summary.companyCommission<0
                }]">{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': item.summary.companyTotal>0
                }, {
                  'text-danger': item.summary.companyTotal<0
                }]">{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
            </tr>
          </tbody>
          <tbody v-if="!accounts.length && !isLoading">
            <tr>
              <td colspan="12" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="12" class="text-center alert-warning">กรุณารอซักครู่...</td>
            </tr>
          </tbody>
          <tfoot v-if="accounts.length && !isLoading">
            <tr>
              <th colspan="2" class="text-center">รวม</th>
              <td class="text-right px-1 text-success" style="border-right: 2px solid #86cfda;">{{ summary.amount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-danger">{{ summary.memberAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-success">{{ summary.memberCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1 text-danger" style="border-right: 2px solid #86cfda;">{{ summary.memberTotal | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': summary.agentAmount>0
                }, {
                  'text-danger': summary.agentAmount<0
                }]">{{ summary.agentAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': summary.agentCommission>0
                }, {
                  'text-danger': summary.agentCommission<0
                }]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1" style="border-right: 2px solid #86cfda;"
                :class="[{
                  'text-success': summary.agentTotal>0
                }, {
                  'text-danger': summary.agentTotal<0
                }]">{{ summary.agentTotal | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': summary.companyAmount>0
                }, {
                  'text-danger': summary.companyAmount<0
                }]">{{ summary.companyAmount | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': summary.companyCommission>0
                }, {
                  'text-danger': summary.companyCommission<0
                }]">{{ summary.companyCommission | amountFormat(2, '0.00') }}</td>
              <td class="text-right px-1"
                :class="[{
                  'text-success': summary.companyTotal>0
                }, {
                  'text-danger': summary.companyTotal<0
                }]">{{ summary.companyTotal | amountFormat(2, '0.00') }}</td>
            </tr>
          </tfoot>
        </table>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import SetsReportService from "@/services/SetsReportService"
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import moment from '@/helpers/moment'
import _ from 'lodash'
import MemberSetsNumbers from './components/MemberSetsNumbers'

export default {
  name: 'SetsMemberReport',
  components: {
    MemberSetsNumbers
  },
  data() {
    return {
      accountId: this.$store.state.account._id,
      rateId: null,
      data: null,
      showName: false,
      isLoading: false,
      display: 'Agent',
      breadcrumbs: [],
      rates: []
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.breadcrumbs[this.breadcrumbs.length - 1].accountId
      }
    },
    accounts() {
      if(!this.data)
        return []

      const reports = this.data.reports.map((report)=>{

        const child = this.data.childs.find((child)=>{
          return child.memberAccountId === report._id.accountId
        })

        const account = this.data.accounts.find((account)=>{
          return account.childAccountId === child.childAccountId
        })

        return {
          ...report,
          agent: account
        }
      })

      const groups = _.groupBy(reports, (report)=>{
        return report.agent.childAccountId
      })

      const accounts = []
      for(const accountId in groups) {

        const summary = groups[accountId].reduce((total, item)=>{
          total.amount += item.amount
          total.memberAmount += item.memberAmount
          total.memberCommission += item.memberCommission
          total.memberTotal += item.memberTotal
          total.agentAmount += item.agentAmount
          total.agentCommission += item.agentCommission
          total.agentTotal += item.agentTotal
          total.companyAmount += item.companyAmount
          total.companyCommission += item.companyCommission
          total.companyTotal += item.companyTotal
          return total
        }, {
          amount: 0,
          memberAmount: 0,
          memberCommission: 0,
          memberTotal: 0,
          agentAmount: 0,
          agentCommission: 0,
          agentTotal: 0,
          companyAmount: 0,
          companyCommission: 0,
          companyTotal: 0
        })

        accounts.push({
          agent: groups[accountId][0].agent,
          summary: summary
        })
      }

      return _.orderBy(accounts, ['agent.username'], ['asc'])
    },
    summary() {
      return this.accounts.reduce((total, item)=>{
        total.amount += item.summary.amount
        total.memberAmount += item.summary.memberAmount
        total.memberCommission += item.summary.memberCommission
        total.memberTotal += item.summary.memberTotal
        total.agentAmount += item.summary.agentAmount
        total.agentCommission += item.summary.agentCommission
        total.agentTotal += item.summary.agentTotal
        total.companyAmount += item.summary.companyAmount
        total.companyCommission += item.summary.companyCommission
        total.companyTotal += item.summary.companyTotal
        return total
      }, {
        amount: 0,
        memberAmount: 0,
        memberCommission: 0,
        memberTotal: 0,
        agentAmount: 0,
        agentCommission: 0,
        agentTotal: 0,
        companyAmount: 0,
        companyCommission: 0,
        companyTotal: 0
      })
    },
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    },
    changed() {
      return `${this.roundId}`
    }
  },
  watch: {
    changed() {
      this.rateId = null
      this.rates = []
      this.getReport()
    }
  },
  methods: {
    getReport() {
      /**
       * ตรวจสอบประเภทหวย
       */
      if(this.$store.state.globalMarket?.marketType==='single') {
        return this.$router.push({name: 'RoundMemberReport'})
      }

      if(!this.roundId)
        return

      this.isLoading = true
      SetsReportService.getRoundReportByMember(this.roundId, {
        status: 'wait',
        accountId: this.accountId,
        rateId: this.rateId
      })
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          if(!this.rates.length) {
            this.rates = response.data?.rates ?? []
          }
          this.setBreadcrumbs(response.data?.agent?.parents ?? [])
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    setBreadcrumbs(data) {
      this.breadcrumbs = data
    },
    toggleChildAccount(accountId) {
      this.display = 'Agent'
      this.accountId = accountId
      this.getReport()
    },
    toggleMemberAccount(accountId) {
      this.display = 'Member'
      this.accountId = accountId
    },
    toggleRate(rateId) {
      this.rateId = rateId
      if(this.display === 'Agent') {
        this.getReport()
      }
    }
  },
  mounted() {
    this.accountId = this.$store.state.account._id
    this.getReport()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 90%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 90%;
      }
    }
  }
}
</style>
<style lang="scss">
.table-sets-report-account {
  tbody {
    tr {
      td {
        .badge {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
